import type { InjectionKey } from 'vue'

// models
import { FollowingData, getFollowingsResponse } from '@/models/relationship'

// modules
import { isValueOf } from '@/utils/zod'

// repositories
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

export const useFollowingList = () => {
  // composables
  const {
    postFollow,
    deleteFollow,
    loadingFollowVketIds,
    tempFollowCancelVketIds,
    tempFollowVketIds,
  } = useFollow()

  // repository
  const repositoryFactory = useRepositoryFactory()
  const relationshipRepository = repositoryFactory.get('relationship')

  // フォローしているユーザーリスト
  const list = ref<FollowingData[]>([])
  // pager情報
  const limit = 8
  const offset = ref(0)

  // フォローしているユーザー一覧取得
  const getFollowings = async (vketId: string, page: number, name?: string) => {
    try {
      offset.value = page * limit - limit
      const response = await relationshipRepository.get.getFollowings({
        vketId,
        limit,
        offset: offset.value,
        name,
      })
      if (!isValueOf(getFollowingsResponse, response)) {
        console.error('An API response is different.')
      }
      list.value = response.users
      return response.users
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  // フォロー一覧取得(トークルーム用)
  const getFollowingsForTalkRoom = async (vketId: string) => {
    try {
      const response = await relationshipRepository.get.getFollowings({
        vketId,
        // NOTE: GETリクエストのクエリにresponseを乗せるため上限を設定する
        limit: 200,
        offset: 0,
      })
      if (!isValueOf(getFollowingsResponse, response)) {
        console.error('An API response is different.')
      }
      list.value = response.users
      return response.users
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  return {
    list: readonly(list),
    limit,
    offset: readonly(offset),
    loadingFollowVketIds: readonly(loadingFollowVketIds),
    tempFollowCancelVketIds: readonly(tempFollowCancelVketIds),
    tempFollowVketIds: readonly(tempFollowVketIds),
    getFollowings,
    postFollow,
    deleteFollow,
    getFollowingsForTalkRoom,
  }
}

export type FollowingListComposable = ReturnType<typeof useFollowingList>

export const followingListInjectionKey: InjectionKey<FollowingListComposable> =
  Symbol('followingList')
